(function (cny, $) {
	cny.functionName = function () {
		var $container = $('');
		if (!$container.length) {
			return;
		}
	};

	cny.wysiwyg = function () {
		tinymce.init({
			selector: ".tinymce",
			theme: "modern",
			height: 300,
			plugins: [
				'advlist autolink lists link image charmap hr anchor pagebreak',
				'searchreplace wordcount visualblocks visualchars code fullscreen',
				'insertdatetime media nonbreaking table contextmenu directionality',
				'emoticons template paste textcolor colorpicker textpattern',
				'filemanager responsivefilemanager codesample'
			],
			relative_urls: false,

			external_plugins: { "responsivefilemanager" : "/plugins/tinymce/plugins/responsivefilemanager/plugin.min.js", "filemanager" : "/filemanager/plugin.min.js"},
			filemanager_title:"Responsive Filemanager",
			external_filemanager_path:"/filemanager/",

			image_advtab: true,

			toolbar1: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent ',
			toolbar2: 'link | responsivefilemanager image media | forecolor backcolor emoticons | codesample'
		});

		$(document).on('focusin', function(e) {
			if ($(e.target).closest(".mce-window").length) {
				e.stopImmediatePropagation();
			}
		});
	};

	cny.colorpicker = function () {
		$(".complex-colorpicker").asColorPicker({
			mode: 'complex'
		});
	};

	cny.moduleSubPanels = function () {

		$(".panel-edit").hide();

		$("#add-panel").on("click",function(){
			var count = $("#hero-list .list-row").length;
			$("#panelForm-sort_order").val(count);

			$(".panel-view").hide();
			$(".panel-edit").show();
			$("#save").parent().hide();

			$('.module-form a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
				if ($(e.target).attr("href") === '#settings') {
					$("#save").parent().show();
				} else if ($(e.target).attr("href") === '#history') {
					$("#save").parent().show();
					$("button.save-module").hide();
				} else {
					$("#save").parent().hide();
				}
				//e.target // newly activated tab
				//e.relatedTarget // previous active tab
			});

			return false;
		});

		$("#cancel-panel, #save-panel").on("click",function(){

			if ($(this).attr("id") === "save-panel") {
				if ($("#panelForm-id").val()) {
					var count = $("#panelForm-id").val();
					var label = ($("#panelForm-label").val() != '') ? $("#panelForm-label").val() : "Panel " + (count + 1);

					var row = $("#hero-list").find("div[data-id='panel_"+count+"']");
					$("#panelForm .copy").each(function() {
						var name = $(this).data("name");

						if (row.find("input." + name).length > 0) {
							row.find("input." + name).val($(this).val());
						}else {
							row.append('<input type="hidden" name="content[panels][' + count + '][' + name + ']" value="' + $(this).val() + '" data-name="' + name + '">');
						}
					});
					row.find(".panel__title").text(label).end()
						.find("button").data("rel", count).end();
				}else {
					var count = $("#hero-list .list-row").length;
					var label = ($("#panelForm-label").val() != '') ? $("#panelForm-label").val() : "Panel " + (count + 1);

					var new_row = $("#new-panel_row").clone();

					$("#panelForm .copy").each(function() {
						var name = $(this).data("name");

						new_row.append('<input type="hidden" name="content[panels][' + count + '][' + name + ']" value="' + $(this).val() + '" data-name="' + name + '">');
					});

					new_row.attr("id", "").attr("data-id", "panel_" + count)
						.find(".panel__title").text(label).end()
						.find("button").data("rel", count).end()
						.removeClass("hidden")
						.appendTo("#hero-list .list-div");
				}
			}

			$("#panelForm .copy").each(function() {
				$(this).val("").attr("src", "");
			});

			$(".panel-view").show();
			$(".panel-edit").hide();
			$("#save").parent().show();

			$('.module-form a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
				if ($(e.target).attr("href") === '#content') {
					$("#save").parent().show();
				}
				//e.target // newly activated tab
				//e.relatedTarget // previous active tab
			});

			return false;
		});

		$("#hero-list").on("click",".panel-edit-btn", function(){

			var row = $("#hero-list").find("div[data-id='panel_"+$(this).data("rel")+"']");

			$("#panelForm-id").val($(this).data("rel"));

			row.find("input[type='hidden']").each(function() {
				var name = $(this).data('name');

				$("#panelForm-" + name).val($(this).val());
				if (name === 'image_path') {
					$("#panelForm-image").attr("src", $(this).val()).show();
				}
			});

			$(".panel-view").hide();
			$(".panel-edit").show();
			$("#save").parent().hide();

			$('.module-form a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
				if ($(e.target).attr("href") === '#settings') {
					$("#save").parent().show();
				} else if ($(e.target).attr("href") === '#history') {
					$("#save").parent().show();
					$("button.save-module").hide();
				} else {
					$("#save").parent().hide();
				}
				//e.target // newly activated tab
				//e.relatedTarget // previous active tab
			});

			return false;
		});

		$("#hero-list").on("click",".panel-del-btn", function(){
			$("#hero-list").find("div[data-id='panel_"+$(this).data("rel")+"']").remove();
		});

		if ($("#panelForm-image_path").val() === '') $("#panelForm-image").hide();
		$('#modal-filemanager').off('hidden.bs.modal').on('hidden.bs.modal', function (e) {
			if ($("#panelForm-image_path").val()) {
				$("#panelForm-image_path").val( "/userFiles/uploads/" + $("#panelForm-image_path").val());
				$("#panelForm-image").attr("src", $("#panelForm-image_path").val()).show();
			}
		});

		//sort panels
		$( "#hero-list .list-div" ).sortable({
			helper: 'clone',
			//handle: ".sort-handle",
			placeholder: "ui-state-highlight",
			'ui-floating': 'auto',
			'start': function (event, ui) {
				$(".ui-state-highlight").height(ui.item.height());
			},
			'sort': function (event, ui) {
				ui.helper.css({'top': ui.position.top + 'px'});
			},
			'update': function (event, ui) {
				var count = 0;
				$("#hero-list .list-row").each(function() {
					$(this).find("input[data-name='sort_order']").val(count);
					count++;
				});
			}
		});

		//toggle certain sections based on other selections
		if ($("input[name='content[fullscreen]']:checked").val() == 'off') {
			$(".fullscreen-off").show();
		}else {
			$(".fullscreen-off").hide();
		}
		$("input[name='content[fullscreen]']").on("change",function(){
			if ($("input[name='content[fullscreen]']:checked").val() == 'off') {
				$(".fullscreen-off").show();
			}else {
				$(".fullscreen-off").hide();
			}
		});

	};

	cny.moduleTabs = function () {

		$('.module-form a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
			if ($(e.target).attr("href") === '#history') {
				$("button.save-module").hide();
			}else {
				$("button.save-module").show();
			}
			//e.target // newly activated tab
			//e.relatedTarget // previous active tab
		});
	};

	cny.moduleHistory = function () {
		var select = $("#restore_time");
		if (select.length > 0) {
			var slider = $("#slider").slider({
				min: 1,
				max: select.find("option").length,
				range: "min",
				value: select[0].selectedIndex + 1,
				slide: function (event, ui) {
					select[0].selectedIndex = ui.value - 1;
					$("#restore_time_display").text(select.find("option:selected").text());
				}
			});

			$(".revert-module").on("click", function () {
				$.post('/admin/cms/revert-module', {history_id: select.val()},
					function (data) {
						$("#module_label-" + data.id).html(data.label);

						$('#modal').modal('hide');
					}, 'json').fail(function () {
					alert("An error occured. Please try again or contact support.");
				});
			});
		}
	};

}(window.cny = window.cny || {}, jQuery));
