/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Cny = {
		// All pages
		'common': {
			/*
			functionNameLocal: function() {

			},
			*/
			init: function() {
				// JavaScript to be fired on all pages
				//window.cny.triggerModal();
				$("body").on("click", ".modal-trigger", function(){
					var el = $(this);
					var target = el.data('target');
					if (target === '' || typeof target === 'undefined') target = '#modal';

					var url = el.data('url');
					if (url === '' || typeof url === 'undefined') url = el.attr("href");

					if (url !== '') {
						$.get( url, function( data ) {
							$(target+" .modal__title").text(el.data('title'));
							$(target+" .modal__body").html(data);

							$(target).modal('show');
						}).fail(function() {
							alert( "An error occured. Please try again or contact support." );
						});
					}

					return false;
				});

				$("body").on("click", ".modal-filemanager", function(){
					var el = $(this);

					var url = el.data('url');
					if (url === '' || typeof url === 'undefined') url = el.attr("href");

					if (url !== '') {
						$.get( url, function( data ) {
							$("#modal-filemanager .modal__body iframe").attr('src',url);

							$("#modal-filemanager").modal('show');
						}).fail(function() {
							alert( "An error occured. Please try again or contact support." );
						});
					}else {
						alert( "An error occured. Please try again or contact support." );
					}

					return false;
				});

				$(".confirm-delete").on("click",function(){
					return confirm("Are you sure you want to delete this entry? It can NOT be undone!");
				});

				tinymce.init({
					selector: ".tinymce",
					theme: "modern",
					height: 500,
					plugins: [
						'advlist autolink lists link image charmap hr anchor pagebreak',
						'searchreplace wordcount visualblocks visualchars code fullscreen',
						'insertdatetime media nonbreaking table contextmenu directionality',
						'emoticons template paste textcolor colorpicker textpattern',
						'filemanager responsivefilemanager codesample'
					],
					relative_urls: false,

					external_plugins: { "responsivefilemanager" : "/plugins/tinymce/plugins/responsivefilemanager/plugin.min.js", "filemanager" : "/filemanager/plugin.min.js"},
					filemanager_title:"Responsive Filemanager",
					external_filemanager_path:"/filemanager/",

					image_advtab: true,

					toolbar1: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent ',
					toolbar2: 'link | responsivefilemanager image media | forecolor backcolor emoticons | codesample'
				});

				//window.cny.functionName();
				//Cny.common.functionNameLocal();
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// CMS Page Editor
		'page_editor': {
			init: function() {
				var wscrolltop = '';
				var sortableFirst = false;

				//fix the sidebar height issue
				var sec_height = $(".section-list")[0].scrollHeight,
					sidebar_height = $(".sidebar--cms")[0].scrollHeight;
				$(".section--cms").css('min-height',(Math.max(sec_height, sidebar_height) + 80) +'px');

				//delete section
				$(".section").on("click",".del-section",function(){
					var id = $(this).data('id');
					$.get( '/admin/cms/delete-section', {page_id:$("#page_id").val(),id:id}, function( data ) {
						$(".panel[data-section='"+id+"']").remove();
					}).fail(function() {
						alert( "An error occured. Please try again or contact support." );
					});

					return false;
				});

				//add section
				$(".section").on("click",".add-section",function(){
					var el = $(this),
						id = el.parent().parent().data('section');
					$.get( '/admin/cms/add-section', {page_id:$("#page_id").val(),prev_id:id,type:$(this).data('type')}, function( data ) {
						$(".panel[data-section='"+id+"']").after(data);
					}).fail(function() {
						alert( "An error occured. Please try again or contact support." );
					});

					return false;
				});

				//clone section
				$(".section").on("click",".clone-section",function(){
					var el = $(this),
						id = el.parent().parent().parent().parent().parent().data('section');
					$.get( '/admin/cms/clone-section', {clone_id:id}, function( data ) {
						$(".panel[data-section='"+id+"']").after(data);
					}).fail(function() {
						alert( "An error occured. Please try again or contact support." );
					});

					return false;
				});

				//sort sections
				$( ".section-list" ).sortable({
					helper: 'clone',
					handle: ".sort-handle",
					placeholder: "ui-state-highlight",
					'ui-floating': 'auto',
					'start': function (event, ui) {
						$(".ui-state-highlight").height(ui.item.height());

						if(sortableFirst){
							wscrolltop = $(window).scrollTop();
						}
						sortableFirst = true;
					},
					'sort': function (event, ui) {
						ui.helper.css({'top': ui.position.top + wscrolltop + 'px'});
					},
					'update': function (event, ui) {
						$.post('/admin/cms/sort-section', $(this).sortable('serialize'), function (data) {
							//
						});
					}
				});

				//sort rows
				$( ".row-list" ).sortable({
					helper: 'clone',
					handle: ".sort-handle",
					placeholder: "ui-state-highlight",
					'ui-floating': 'auto',
					'start': function (event, ui) {
						$(".ui-state-highlight").height(ui.item.height());

						if(sortableFirst){
							wscrolltop = $(window).scrollTop();
						}
						sortableFirst = true;
					},
					'sort': function (event, ui) {
						ui.helper.css({'top': ui.position.top + wscrolltop + 'px'});
					},
					'update': function (event, ui) {
						$.post('/admin/cms/sort-row', $(this).sortable('serialize'), function (data) {
							//
						});
					}
				});

				//save page title/code
				$("button.page-editor-form").on("click",function(){
					//save
					$.post( '/admin/cms/page-save-partial/page_id/'+$("#page_id").val(),
						$( ".page-editor-form" ).serialize(),
						function(data) {
							$(".current-page-title").text(data.title);
							$(".current-page-code").text(data.code);

							$(".page-editor-default").show();
							$(".page-editor-form").hide();
						}, 'json').fail(function() {
						alert( "An error occured. Please try again or contact support." );
					});

					return false;
				});
				$("button.page-editor-default").on("click",function(){
					$(".page-editor-default").hide();
					$(".page-editor-form").show();
				});
				$(".page-title").on("keyup",function(){
					$.get( '/admin/cms/slug-title', {page_id:$("#page_id").val(),title:$(this).val()}, function( data ) {
						$(".page-code").val(data);
					}).fail(function() {
						alert( "An error occured. Please try again or contact support." );
					});
				});

				//save page SEO info
				$("body").on("submit",".page-seo-form",function(){
					//save
					$.post( '/admin/cms/page-save-partial/page_id/'+$("#page_id").val(),
						$( ".page-seo-form" ).serialize(),
						function(data) {
							$("#page-meta_title").text(data.meta_title);
							$("#page-meta_keywords").text(data.meta_keywords);
							$("#page-meta_description").text(data.meta_description);
							$("#page-tracking_code").html(data.tracking_code);

							$('#modal').modal('hide');
						}, 'json').fail(function() {
						alert( "An error occured. Please try again or contact support." );
					});

					return false;
				});

				//choose columns
				$("body").on("click",".column_selector", function(){
					var row_id = $("#row_id").val();
					var col_type = $(this).find(".row_selection").data("col");

					$.get( '/admin/cms/add-columns', {row_id:row_id,col_type:col_type}, function(data) {
						$("#section_row-"+row_id).replaceWith(data);

						$('#modal').modal('hide');
					}).fail(function() {
						alert( "An error occured. Please try again or contact support." );
					});
				});

				//delete row
				$(".section").on("click",".del-row",function(){
					var id = $(this).data('id');
					$.get( '/admin/cms/delete-row', {id:id}, function( data ) {
						$(".section_row[data-section_row_id='"+id+"']").remove();
					}).fail(function() {
						alert( "An error occured. Please try again or contact support." );
					});

					return false;
				});

				//add new row
				$(".section").on("click",".add-row",function(){
					var el = $(this),
						last_row = $(el).parent().parent().parent().find('.row-list .section_row:last-child');
					$.get( '/admin/cms/add-row', {section_id:el.data('section_id'),prev_id:last_row.data('section_row_id')}, function( data ) {
						if (last_row.length > 0) {
							last_row.after(data);
						}else {
							$(el).parent().parent().parent().find('.row-list').html(data);
						}
					}).fail(function() {
						alert( "An error occured. Please try again or contact support." );
					});

					return false;
				});

				//clone row
				$("body").on("click",".clone-row",function(){
					var el = $(this),
						section = el.parent().parent().parent().parent().parent().parent(),
						id = section.data('section_row_id'),
						last_row = $(section).parent().parent().find('.row-list .section_row:last-child');
					$.get( '/admin/cms/clone-row', {clone_id:id}, function( data ) {
						if (last_row.length > 0) {
							last_row.after(data);
						}else {
							$(section).parent().parent().find('.row-list').html(data);
						}
					}).fail(function() {
						alert( "An error occured. Please try again or contact support." );
					});

					return false;
				});

				//save module
				$("body").on("submit",".module-form",function(){
					//save module form
					$.post( '/admin/cms/save-module',
						$( ".module-form" ).serialize(),
						function(data) {
							$("#module_label-"+data.id).html(data.label);

							$('#modal').modal('hide');
						}, 'json').fail(function() {
						alert( "An error occured. Please try again or contact support." );
					});

					return false;
				});

				//refresh row (to show change to module)
				$("body").on("click",".row-refresh", function(){
					var column_id = $(this).data('column_id');

					$.getJSON( '/admin/cms/update-row', {column_id:column_id}, function(data) {
						$("#section_row-"+data.row_id).replaceWith(data.content);
					}).fail(function() {
						alert( "An error occured. Please try again or contact support." );
					});
				});

				//delete module
				$(".section").on("click",".del-module", function(){
					var module_id = $(this).data('module_id');

					$.getJSON( '/admin/cms/delete-module', {module_id:module_id}, function(data) {
						$("#section_row-"+data.row_id).replaceWith(data.content);
					}).fail(function() {
						alert( "An error occured. Please try again or contact support." );
					});
				});

				//sort modules
				$(".module-list").sortable({
					helper: 'clone',
					//handle: ".sort-handle",
					placeholder: "ui-state-highlight",
					'ui-floating': 'auto',
					'start': function (event, ui) {
						$(".ui-state-highlight").height(ui.item.height());

						if (sortableFirst) {
							wscrolltop = $(window).scrollTop();
						}
						sortableFirst = true;
					},
					'sort': function (event, ui) {
						ui.helper.css({'top': ui.position.top + wscrolltop + 'px'});
					},
					'update': function (event, ui) {
						$.post('/admin/cms/sort-module', $(this).sortable('serialize'), function (data) {
							//
						});
					}
				});
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Cny;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
